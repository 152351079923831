import React from 'react';
import './ExpertisePage.css';
import { services } from './ExpertisePageUtils';

const backgroundImageUrl = "https://images.unsplash.com/photo-1565008447742-97f6f38c985c?q=80&w=3431&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"

function ExpertisePage() {
  return (
    <div className='text-slate-700'>
      <div className="">
        <div style={{ height: '40vh', minHeight: '300px', background: `url(${backgroundImageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center', width: '100%' }}>
          <div className='flex flex-col font-light items-center justify-center h-full'>
            <h1 className='text-3xl font-semibold mt-40 mb-10 text-white'>Expertise</h1>
          </div>
        </div>
      </div>

      <div className='flex flex-col'>
        <div className='min-h-20 flex flex-col items-center justify-center font-extralight'>
          <p className='text-center text-sm md:text-lg max-w-2xl p-4'>We provide a wide range of <b className='font-medium'>Mechanical, Electrical, and Plumbing</b> (MEP) services to help you navigate the complex regulations of the New York City Building Code.</p>
        </div>
        <hr />
        <div className='flex flex-col items-center justify-center my-10'>
          <div className='flex flex-wrap justify-center items-center h-auto lg:w-5/6'>
            {
              Object.keys(services).map((service, index) => {
                return (
                  <div key={index} className='md:w-3/4 lg:w-3/4 p-2 md:m-1'>
                    <div className='bg-white rounded-lg shadow-lg p-4'>
                      <div className='flex flex-row justify-stretch items-center'>
                        <h3 className='lg:text-lg font-medium ml-3 mb-2'>{service}</h3>
                      </div>
                      <ul>
                        {services[service].map((item, index) => {
                          return (
                            <li className='custom-li mb-2 font-light' key={index}>{item}</li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
    </div >
  );
}

export default ExpertisePage;