import Home from './pages/Home';
import Footer from './components/Footer';
import Navbar from './components/nav/NavBar';
import ExpertisePage from './pages/ExpertisePage';
import AboutPage from './pages/AboutPage';
import NotFound from './pages/NotFound'
import { Routes, Route } from 'react-router-dom';

function App() {
    return (
        <div>
            <Navbar />
            <Routes>
                <Route path={"/"} element={<Home />} />
                <Route path={"/home"} element={<Home />} />
                <Route path="/expertise" element={<ExpertisePage />} />
                <Route path="/about" element={<AboutPage />} />
                {/* Wildcard route for handling unknown routes */}
                <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
        </div>
    );
}

export default App;


//TODO block everything from rendering as the images are loading

//TODO lookup the use of sections tag in the app