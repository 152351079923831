import React, { useState, useEffect } from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import Loader from "./Loader"

const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundPosition: 'center bottom',
  backgroundSize: 'cover',
  height: '80vh',
  minHeight: '600px',
};


// const textOverlayStyle = {
//   position: 'absolute',
//   top: '70vh', // adjust as needed
//   left: '50%',
//   transform: 'translateX(-50%)',
//   color: '#EEE',
//   fontSize: '2vh',
//   width: '30vh',
//   textAlign: 'center',
//   zIndex: 10,
//   backgroundColor: 'rgba(200, 200, 200, 0.3)',
//   borderRadius: '5px',
//   padding: '1px 20px',
// };

const slideImages = [
  {
    url: 'https://images.unsplash.com/photo-1568515387631-8b650bbcdb90?auto=format&fit=crop&q=80&w=3270&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    caption: 'Slide 1'
  },
  {
    url: 'https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    caption: 'Slide 2'
  },
];

function Slideshow() {
  const [loading, setLoading] = useState(true);
  const [loadedImages, setLoadedImages] = useState(0);

  useEffect(() => {
    if (loadedImages === slideImages.length) {
      setLoading(false);
    }
  }, [loadedImages]);

  const handleImageLoad = () => {
    setLoadedImages(prev => prev + 1);
  }

  //TODO: for deployment or final version, explore downloading the images and rendering them instead of using the url
  return (
    <div className="slide-container" style={{ position: 'relative' }}>
      {loading && (
        <div style={{ height: '80vh', minHeight: '600px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Loader />
        </div>
      )}
      <div style={{ visibility: loading ? 'hidden' : 'visible' }}>
        <Fade duration={5000} transitionDuration={1300} easing='cubic' arrows={false} pauseOnHover={false} canSwipe={true}>
          {slideImages.map((slideImage, index) => (
            <div key={index} style={divStyle}>
              <img src={slideImage.url} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} onLoad={handleImageLoad} />
            </div>
          ))}
        </Fade>
      </div>
      {
      //TODO: add text overlay
      /* <div style={textOverlayStyle}>
        Tower Consulting Engineers
      </div> */}
    </div>
  );
}

export default Slideshow;