import Intro from '../components/intro/Intro';
import ServicesBody from '../components/servicesbody/ServicesBody';
import Slideshow from '../components/slideshow/Slideshow';

function Home() {
    return (
        <>
            <Slideshow />
            <Intro />
            <hr />
            <ServicesBody />
        </>
    );
}

export default Home;