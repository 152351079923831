import { Link } from "react-router-dom"

// Navigation Links that appear on the NavBar when the screen is large enough
function NavBarPageLinks({ myRef, isMenuOpen, handleMenuToggle }) {
    return (
        <div ref={myRef} className={`items-center justify-between w-full md:flex md:w-auto md:order-1 ${isMenuOpen ? '' : 'hidden'}`} id="navbar-sticky">
            <ul className="flex flex-col p-4 md:p-0 mt-4 font-extralight text-sm border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white">
                <li>
                    <Link to="/home" onClick={handleMenuToggle} className="block py-2 pl-3 pr-4 text-gray-600 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-black md:p-0" aria-current="page">HOME</Link>
                </li>
                <li>
                    <Link to="/expertise" onClick={handleMenuToggle} className="block py-2 pl-3 pr-4 text-gray-600 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-black md:p-0">EXPERTISE</Link>
                </li>
                <li>
                    <Link to="/about" onClick={handleMenuToggle} className="block py-2 pl-3 pr-4 text-gray-600 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-black md:p-0">ABOUT</Link>
                </li>
            </ul>
        </div>
    )
};

export default NavBarPageLinks;