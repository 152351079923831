function NavBarContactButton({ onClick }) {
    return (
        <button
            onClick={onClick}
            type="button"
            className="block py-2 pl-3 pr-4 text-gray-600 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-black md:p-0">
            CONTACT
        </button>
    )
};

export default NavBarContactButton;