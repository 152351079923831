import React from 'react';

const ClientCard = ({ client, index, imageURL }) => {
    return (
        <div key={index} className='rounded-sm relative'>
            <div style={{ background: `url(${imageURL})`, backgroundSize: 'cover', backgroundPosition: 'center', width: '300px', height: '200px' }}>
                <div className="absolute top-0 left-0 right-0 bottom-0 bg-gray-600 bg-opacity-10 hover:bg-opacity-20"></div>
                {/* Rest of your content */}
            </div>
        </div>
    );
};

export default ClientCard;
