import React from 'react';

function NotFound() {

    return (
        <div className='flex justify-center items-center w-full h-screen text-center font-extralight md:text-xl'>
            <div className='flex-col justify-center items-center'>
                <p>This Page does not exist.</p>
                <p>Kindly use page links from the Navigation Bar above.</p>
            </div>
        </div>
    );
}

export default NotFound;
