import { Link } from "react-router-dom"


function Logo() {
    return (
        <Link to="/home" className="flex items-start">
            {/* Uncomment and use if you have a logo image */}
            {/* <img src="your-logo-url" className="h-8 mr-3" alt="Flowbite Logo" /> */}
            <div className="flex flex-col items-start font-extralight">
                <span className="text-sm md:text-1">TOWER</span>
                <span className="text-sm md:text-1">CONSULTING ENGINEERS</span>
            </div>
        </Link>
    )
};

export default Logo;



