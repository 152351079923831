import React from 'react';

const Intro = () => {
    return (
        <div className="min-h-20 flex justify-center items-center font-extralight font">
            <p className="text-center text-sm md:text-lg max-w-2xl p-4">
                Tower Consulting Engineers is a New York City based Professional Engineering and consulting firm specializing in Life Safety, Fire Protection, MEP, and special inspection services.
            </p>
        </div>
    );
}

export default Intro;
