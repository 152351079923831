import React from "react";
import { Link } from "react-router-dom";

function Footer() {
    const navigationsLinks = [
        ["HOME", "/home"],
        ["EXPERTISE", "/expertise"],
        ["ABOUT", "/about"]
    ];

    return (
        <div className="flex flex-col justify-between max-h-80">
            <hr />
            <div className="flex justify-around p-5 ml-3 text-xs md:text-sm font-extralight">
                <div className="flex flex-col p-5">
                    <p className="px-3 pt-2">120 West 31st Street, 7th Floor</p>
                    <p className="px-3 pt-0">New York, New York</p>
                    <p className="px-3 pt-4">212 444 8786</p>
                </div>
                <div className="flex flex-col p-5 mr-3">
                    <div></div>
                    {(
                        navigationsLinks.map((link, index) => (
                            <Link to={link[1]} key={index} className="text-gray-600 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-black px-3 pt-2 pb-1">{link[0]}</Link>
                        )
                        )
                    )}
                </div>
            </div>
            <hr />
            <div className="flex justify-center pb-20 pt-8 font-extralight">
                <p className="text-xs">2024 &copy; Tower Consulting Engineers</p>
            </div>
        </div>
    );
}

export default Footer;
