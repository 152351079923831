import React from 'react';

const services = [
    {
        title: "Fire Alarm Design",
        icon: "fas fa-fire-alt",
        description: "Our designs adhere to the latest requirements of the NYC Building and Fire Code, prioritizing the safety of occupants and property."
    },
    {
        title: "Fire Alarm Violations",
        icon: "fas fa-bell",
        description: "We help you identify and correct fire alarm violations to avoid penalties and ensure compliance with the New York City Fire Code."
    },
    {
        title: "Building Code Consulting",
        icon: "far fa-building",
        description: "We provide building code consulting services to help you navigate the complex regulations and requirements of the New York City Building Code."
    },
    {
        title: "Fire Safety Consulting",
        icon: "fas fa-hard-hat",
        description: "We provide fire safety consulting services to help you develop and implement fire safety plans that meet the requirements of the New York City Fire Department."
    },
    {
        title: "Emergency Action Plan Development",
        icon: "fas fa-ruler-combined",
        description: "Our experts develop customized Emergency Action Plans (EAPs) that not only comply with NYC's stringent legal requirements but also provide clear, effective protocols for occupants during emergencies."
    },
    {
        title: "MEP Design",
        icon: "fas fa-pencil-alt",
        description: "We provide design consulting services to help you create functional and aesthetically pleasing spaces that meet your business needs and comply with building codes and regulations."
    }
];

function ServicesBody() {
    return (
        //TODO Make all of the html body background color the same
        <div className="w-full flex justify-center items-center text-sm pb-20">
            <div className="flex flex-wrap justify-center items-center h-auto lg:w-3/4">
                {services.map((service, index) => (
                    <div key={index} className="w-1/2 md:w-1/3 lg:w-1/3 p-2 md:m-2 lg:m-4">
                        <div className="bg-white rounded-lg shadow-lg p-4">
                            <div className="flex flex-row justify-center items-center">
                                <i className={service.icon + " mb-2 mr-4"}></i>
                                <h3 className="lg:text-lg font-medium mb-2 ">{service.title}</h3>
                            </div>
                            <p className="text-gray-600">{service.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}


export default ServicesBody;
