
const imageDivStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundPosition: 'center bottom',
    backgroundSize: 'cover',
    height: '80vh',
    minHeight: '600px',
};

const services = {
    'Fire Alarm': [
        "Fire Alarm designs meet all NFPA and Fire Department requirements minimizing plan examination approval turnaround.",
        "Code consulting services provide clients with detailed drawing reviews based on our experience of the New York City and National building codes.",
        "Our dedicated expeditors allow for full control of the filing process.",
        "Pre-inspection testing provides clients with the peace of mind knowing that when the official FDNY inspection takes place the best possible outcome occurs.",
        "Administration of bid leveling for projects of all sizes."
    ],
    'Auxiliary Radio Communication System': [
        'Full suite of design capabilities including radio frequency design and analysis.',
        'ARCS design meet all requirements dictated by the Fire Department of New York.',
        'Full expediting service for approval of ARCS drawings from the Fire Department of New York',
        'In-house capacity for initial and 5-year building  ARCS commissioning surveys.'
    ],
    'Sprinkler System': [
        'We design fire sprinkler systems that meet the requirements of the New York City Fire Department and ensure the safety of your building occupants.',
        'Our team specializes in designing and implementing various types of automatic sprinkler systems, including wet and dry pipe systems, fire pumps, and alternative fire extinguishing systems.',
        'We ensure compliance with Local Law, NFPA, and ICC regulations to guarantee the highest level of safety for your building.',
        'Our services also include 3rd party special inspections to ensure the quality and reliability of the sprinkler system installation.'
    ],
    'Electrical': [
        'We design electrical systems that meet the requirements of the New York City Electrical Code and ensure the safety of your building occupants.',
        'Our electrical services include comprehensive lighting system design and installation.',
        'We specialize in energy-efficient electrical solutions to reduce costs and environmental impact.'
    ],
    'Plumbing': [
        'We design plumbing systems that meet the requirements of the New York City Plumbing Code and ensure the safety of your building occupants.',
        'Our plumbing services include sustainable plumbing designs that are both eco-friendly and compliant with local regulations.'
    ],
    'HVAC': [
        'We design HVAC systems that meet the requirements of the New York City Mechanical Code and ensure the safety of your building occupants.',
        'Our team is proficient in designing heating, ventilation, and air-conditioning systems for both residential and commercial projects.',
        'All systems are modeled with an aim in dependability, efficiency, and comfort, ensuring a sustainable and cost-effective solution for your HVAC needs.'
    ],
};

export { imageDivStyle, services };